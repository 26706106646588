import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { ValidationHelper } from '@helpers/validation.helper';
import { TechnicalTest } from '@interfaces/onboarding.interface';
import { BackOfficeService } from '@services/back-office.service';
import { OnBoardingService } from '@services/onboarding.service';
import CustomEditor from 'ckeditor5-ng';
import dayjs from 'dayjs';
import { combineLatest } from 'rxjs';
import { MAX_LENGTH, URL_PATTERN } from 'src/app/shared/constants';

enum TestType {
  PDF = 1,
  LINK = 2,
}

@Component({
  selector: 'technical-test-form',
  templateUrl: './technical-test.component.html',
  styleUrls: [
    './technical-test.component.scss',
    '../../../../pages/job/job-detail/job-detail.component.scss',
    '../../../../pages/talent/about/about.component.scss'
  ]
})
export class TechnicalTestComponent {
  @Input() onBoardingId: string = '';
  @Input() jobId: string = '';
  @Input() formType: string = '';
  @Output() afterSubmit = new EventEmitter<any>();
  public isLoading = false;
  public technicalTest !: TechnicalTest;
  public link = '';
  public minDate = dayjs().format('YYYY-MM-DD');
  public isUploadingFile = false;
  public selectedFile: File | null = null;
  public selectedFileName: string | null = null;
  public isTemplate = false;
  public isBackOfficeRole = false;
  public companyId = '';

  public technicalTestForm !: FormGroup<{
    description: FormControl<string>,
    testTypeOption: FormControl<number | null>,
    answerTypeOption: FormControl<number | null>,
    answerEmail: FormControl<string | null>,
    testLink: FormControl<string>,
    sla: FormControl<number>,
  }>;
  public emailNotificationList: Array<string> = [];
  public errorMessage: string = '';
  public isAboutValid = true;
  public Editor = CustomEditor;

  constructor
    (
      private cdr: ChangeDetectorRef,
      private onBoardingService: OnBoardingService,
      private router: Router,
      private toastManager: ToastManager,
      private backOfficeService: BackOfficeService,
      private route: ActivatedRoute,
    ) {
    this.isBackOfficeRole = StorageHelper.isBackOfficeRole;
    this.technicalTestForm = new FormGroup({
      description: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_LARGE)], nonNullable: true }),
      sla: new FormControl<number>({
        value: 1,
        disabled: false
      }, { validators: [Validators.required, Validators.min(1), Validators.max(30)], nonNullable: true }),
      testTypeOption: new FormControl<number | null>({
        value: null,
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      testLink: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, Validators.pattern(URL_PATTERN)], nonNullable: true }),
      answerTypeOption: new FormControl<number | null>({
        value: null,
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      answerEmail: new FormControl<string>({
        value: '',
        disabled: false
      }),
    });
  }

  async ngOnInit() {
    if (this.isBackOfficeRole && this.jobId) {
      this.backOfficeService.setActiveSidebarMenu('corporate')
      this.backOfficeService.setActiveSidebarSubMenu('details', 'job-detail');
      this.technicalTest = await this.backOfficeService.getTestTemplateById(this.jobId);
    }
    if (this.formType === 'jobPost') {
      combineLatest([this.route.paramMap, this.route.data])
        .subscribe(async ([params, data]: [any, any]) => {
          try {
            this.isLoading = true;
            const jobId = params.get('jobId');
            this.companyId = params.get('companyId');
            if (!jobId) {
              this.router.navigate(['/not-found'])
              return;
            }
            this.jobId = jobId;
            if (this.isBackOfficeRole && this.jobId) {
              this.technicalTest = await this.backOfficeService.getTestTemplateById(this.jobId);
            } else {
              this.technicalTest = await this.onBoardingService.getTestTemplateById(jobId);
            }
            this.setTechnicalTestFormValue();
          } catch (error: any) {
            this.toastManager.showError(null, error.message)
          } finally {
            this.isLoading = false
          }
        });
    }
  }

  private setTechnicalTestFormValue() {
    if (this.technicalTest) {
      this.technicalTestForm.get('description')?.setValue(this.technicalTest.description)
      this.technicalTestForm.get('sla')?.setValue(this.technicalTest.sla)
      this.technicalTestForm.get('testTypeOption')?.setValue(this.technicalTest.testType)
      this.technicalTestForm.get('testLink')?.setValue(this.technicalTest.link)
      this.technicalTestForm.get('answerTypeOption')?.setValue(this.technicalTest.answerType)
      if (this.technicalTest.answerEmail) {
        this.technicalTestForm.get('answerEmail')?.setValue(this.technicalTest.answerEmail)
      }
      this.selectedFileName = this.technicalTest.link
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onAnswerOptionChange() {
    const answerOption = this.technicalTestForm.get('answerTypeOption')?.value;

    if (answerOption === 3) {
      this.technicalTestForm.get('answerEmail')?.setValidators([Validators.required, Validators.email]);
    } else {
      this.technicalTestForm.get('answerEmail')?.clearValidators();
    }

    this.technicalTestForm.get('answerEmail')?.updateValueAndValidity();
  }

  public uploadTestQuestion() {
    const fileInput = document.getElementById('uploadTestQuestion');
    if (fileInput) {
      fileInput.click();
    }
  }

  public onTestTypeChange(event: any) {
    this.technicalTestForm.get('testLink')?.setValue('')
    this.selectedFileName = '';
  }

  public async onFileSelected(event: any, type: string) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = inputFile.files[0].name;
      try {
        this.isUploadingFile = true;
        ValidationHelper.uploadFileValidator(inputFile);
        if (type === 'upload-test-question') {
          if (this.isBackOfficeRole) {
            const { fileUrl } = await this.backOfficeService.uploadTestQuestion(this.jobId, this.selectedFile);
            this.link = fileUrl;
          } else {
            const { fileUrl } = await this.onBoardingService.uploadTestQuestion(this.jobId, this.selectedFile);
            this.link = fileUrl;
          }
          this.technicalTestForm.get('testLink')?.setValue(this.link)
          this.cdr.detectChanges();
        }
        if (type === 'nda') {
          // await this.onBoardingService.uploadNDA('124', this.selectedFile);
        }
      } catch (error: any) {
        this.toastManager.showError('Upload failed', error.message)
      } finally {
        this.isUploadingFile = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
      this.isUploadingFile = false;
    }
  }

  private mapJobDetailPayload() {
    const title = this.jobId;
    const description = this.technicalTestForm.get('description')?.getRawValue();
    const testTypeOption = this.technicalTestForm.get('testTypeOption')?.getRawValue();
    const answerTypeOption = this.technicalTestForm.get('answerTypeOption')?.getRawValue();
    const sla = this.technicalTestForm.get('sla')?.getRawValue();
    const answerEmail = this.technicalTestForm.get('answerEmail')?.getRawValue();
    const link = Number(testTypeOption) === TestType.LINK ? this.technicalTestForm.get('testLink')?.getRawValue() : this.link;
    return {
      onboardingId: this.onBoardingId,
      title,
      description,
      testType: Number(testTypeOption),
      link,
      sla,
      answerEmail,
      answerType: Number(answerTypeOption)
    }
  }

  onPrivacyPolicyCheckClick(event: any) {
    const isEmailNotifiactionChecked = this.technicalTestForm.get('emailNotificationCheck')?.value;

    if (!isEmailNotifiactionChecked) {
      this.technicalTestForm.get('emailNotificationInput')?.disable();
      return;
    }

    this.technicalTestForm.get('emailNotificationInput')?.enable();
  }

  public async onClickNext() {
    try {
      this.isLoading = true;
      if (!this.technicalTestForm.valid && this.formType === 'jobPost') {
        this.toastManager.showError('Form invalid', 'Technical test failed to save');
        return;
      }
      const payload: any = this.mapJobDetailPayload();
      if (this.technicalTestForm.valid) {
        if (this.formType === 'jobPost') {
          payload.jobId = this.jobId;
          if (this.isBackOfficeRole) {
            await this.backOfficeService.createJobTechnicalTestTemplate(this.companyId, payload);
            this.toastManager.showSuccess('Technical test saved successfully');
            this.router.navigate(['/corporate/details/job-preview', this.companyId, this.jobId]);
            return;
          } else {
            await this.onBoardingService.createJobTechnicalTest(payload);
            this.toastManager.showSuccess('Technical test saved successfully');
            this.router.navigate(['/job/preview', this.jobId])
            return;
          }
        }

        if (this.isBackOfficeRole) {
          payload.jobId = this.jobId;
          await this.backOfficeService.createJobTechnicalTest(payload);
          this.toastManager.showSuccess('Technical test saved successfully');
          const dataToEmit = {
            onBoardingId: this.onBoardingId,
            jobId: this.jobId
          };
          this.afterSubmit.emit(dataToEmit);
          return;
        }
      }

      await this.onBoardingService.createTechnicalTest(payload);
      this.toastManager.showSuccess('Technical test saved successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public onClickBackButton() {
    if (this.formType === 'jobPost') {
      if (this.isBackOfficeRole) {
        this.router.navigate(['corporate/details/job-preference', this.companyId, this.jobId])
      } else {
        this.router.navigate(['/job/applicant-preference', this.jobId])
      }
    }
  }

  public getWordCount() {
    const description = this.technicalTestForm.get('description')?.value;
    const words = description && description.trim().split(/\s+/);
    return words?.length || 0;
  }
}
