import { Component, Input, OnChanges } from '@angular/core';

import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';

@Component({
  selector: 'simple-talent-list',
  templateUrl: './simple-talent-list.component.html',
  styleUrls: ['./simple-talent-list.component.scss']
})
export class SimpleTalentListComponent implements OnChanges {
  @Input() fetchTalentData!: (page: number, pageSize: number, status: string) => Promise<any>;
  @Input() fetchTalentDetail!: (string: string) => Promise<any>;
  @Input() status!: string;
  public applicationStatus!: number;
  public isLoading = false;
  public isBackOfficeRole = false;

  talents: Array<any> = [];
  currentPage = 1;
  totalPages = 1;
  pageSize = 10;
  paginationNumberList: number[] = [];
  selectedTalent!: any;

  constructor
    (
      private toastManager: ToastManager,
    ) {
    this.isBackOfficeRole = StorageHelper.isBackOfficeRole;
  }

  ngOnChanges() {
    this.fetchData();
  }

  async fetchData() {
    if (this.fetchTalentData) {
      const response = await this.fetchTalentData(this.currentPage, this.pageSize, this.status);
      this.talents = response.talents;
      this.totalPages = response.totalPages;

      // Generate pagination number list
      this.paginationNumberList = Array.from({ length: this.totalPages }, (_, i) => i + 1);

      // Load the first talent's details by default
      if (this.talents.length) {
        await this.loadTalentDetail(this.talents[0]);
      }
    }
  }

  async loadTalentDetail(talent: any) {
    this.applicationStatus = talent.applicationStatus;
    if (this.fetchTalentDetail) {
      this.isLoading = true;
      try {
        this.selectedTalent = await this.fetchTalentDetail(talent.id);
      } catch (error: any) {
        this.toastManager.showError(null, error.message);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async onSelectTalent(talent: any) {
    await this.loadTalentDetail(talent);
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.fetchData();
  }
}
