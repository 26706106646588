<div class="job-container-test">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="job-form-test">
    <form id="technicalTestForm" (keydown.enter)="$event.preventDefault()" class="form-body-test"
      [formGroup]="technicalTestForm" (ngSubmit)="onClickNext()">
      <div class="form-job">
        <div class="form-label-with-border-line">
          <div class="form-label-input" required>
            Technical Test
          </div>
          <div class="break-line">
          </div>
        </div>
        <div class="input-text-space">
          <ckeditor id="description" name="description" [editor]="Editor" [disableTwoWayDataBinding]="false"
            formControlName="description"> </ckeditor>
          <div class="text-right">
            <label>{{ getWordCount() }}/{{ 'TEXT_AREA_LARGE' | maxLength }} words </label>
          </div>
          <div class="invalid-feedback-text"
            *ngIf="technicalTestForm.get('description')?.touched && technicalTestForm.get('description')?.hasError('required')">
            Description is required
          </div>
          <div class="invalid-feedback-text"
            *ngIf="technicalTestForm.get('description')?.touched && technicalTestForm.get('description')?.hasError('textAreaMaxLength')">
            Description length maximum {{ 'TEXT_AREA_LARGE' | maxLength }} words
          </div>
        </div>
        <div class="job-post-option-group">
          <div class="radio-label">
            <input type="radio" (change)="onTestTypeChange($event)" formControlName="testTypeOption"
              name="testTypeOption" [value]="1" id="testTypeOption" class="ec-form-check-input-sm">
            <label for="testTypeOption" class="ec-form-check-label">Upload Test (.pdf)</label>
          </div>
          <div *ngIf="technicalTestForm.controls.testTypeOption.value === 1">
            <div class="test-filename">{{selectedFileName || '(No file selected)' | stringCase: 'upload-filename'}}
              <a *ngIf="technicalTestForm.controls.testLink.value" target="_blank"
                [href]="technicalTestForm.controls.testLink.value"> Preview</a>
            </div>
            <div class="btn-rounded bg-primary-soft" (click)="uploadTestQuestion()">
              <label>
                <i class="bi bi-upload"></i>
                {{ isUploadingFile ? 'Uploading ...': 'Upload'}}
              </label>
              <span *ngIf="isUploadingFile" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <input type="file" style="display: none;" id="uploadTestQuestion" class="input-file"
                (change)="onFileSelected($event, 'upload-test-question')" accept=".pdf">
            </div>
          </div>
          <div class="radio-label">
            <input type="radio" (change)="onTestTypeChange($event)" formControlName="testTypeOption"
              name="testTypeOption" [value]="2" id="testTypeOption" class="ec-form-check-input-sm">
            <label for="testTypeOption" class="ec-form-check-label">Set up link</label>
          </div>
          <div *ngIf="technicalTestForm.controls.testTypeOption.value === 2"
            class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-link-45deg"></i>
            </span>
            <input class="ec-form-control-rounded ec-is-invalid" placeholder="ex: https://github.com"
              formControlName="testLink">
            <div class="ec-invalid-feedback"
              *ngIf="technicalTestForm.controls.testLink.touched && technicalTestForm.controls.testLink.hasError('required')">
              Test link is required
            </div>
            <div class="ec-invalid-feedback"
              *ngIf="technicalTestForm.controls.testLink.touched && technicalTestForm.controls.testLink.hasError('pattern')">
              Test link should be a valid url
            </div>
          </div>
        </div>
        <div class="form-label-with-border-line">
          <div class="form-label-input">
            Expected Answer In
          </div>
          <div class="job-post-status">
            *you can skip this if the answer are included in the test link
          </div>
        </div>
        <div class="job-post-option-group">
          <div class="radio-label">
            <input type="radio" (change)="onAnswerOptionChange()" formControlName="answerTypeOption"
              name="answerTypeOption" [value]="1" id="testTypeOption" class="ec-form-check-input-sm">
            <label for="answerTypeOption" class="ec-form-check-label">Upload answer (.pdf)</label>
          </div>
          <div class="radio-label">
            <input type="radio" (change)="onAnswerOptionChange()" formControlName="answerTypeOption"
              name="answerTypeOption" [value]="2" id="testTypeOption" class="ec-form-check-input-sm">
            <label for="answerTypeOption" class="ec-form-check-label">Link (Github, Google Drive, etc...)</label>
          </div>
          <div class="radio-label">
            <input type="radio" (change)="onAnswerOptionChange()" formControlName="answerTypeOption"
              name="answerTypeOption" [value]="3" id="testTypeOption" class="ec-form-check-input-sm">
            <label for="answerTypeOption" class="ec-form-check-label">Send Email</label>
          </div>
        </div>
        <div *ngIf="technicalTestForm.controls.answerTypeOption.value === 3"
          class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-at"></i>
          </span>
          <input class="ec-form-control-rounded ec-is-invalid" placeholder="Email address"
            formControlName="answerEmail">
          <div class="ec-invalid-feedback"
            *ngIf="technicalTestForm.controls.answerEmail.touched && technicalTestForm.controls.answerEmail.hasError('required')">
            Answer email is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="technicalTestForm.controls.answerEmail.touched && technicalTestForm.controls.answerEmail.hasError('email')">
            Answer email should be a valid email
          </div>
        </div>
        <div class="form-label-with-border-line">
          <div class="form-label-input">
            Set SLA
          </div>
          <div class="job-post-status">
            *The deadline starts from today in calendar days.
          </div>
        </div>
        <div class="ec-input-group-rounded input-text-space" style="width: 200px;">
          <span class="ec-input-group-text">
            <i class="bi bi-calendar2-week-fill"></i>
          </span>
          <input class="ec-form-control-rounded ec-is-invalid" formControlName="sla" type="number" placeholder="1">
          <div class="ec-invalid-feedback"
            *ngIf="technicalTestForm.controls.sla.touched && technicalTestForm.controls.sla.hasError('required')">
            SLA is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="technicalTestForm.controls.sla.touched && technicalTestForm.controls.sla.hasError('min')">
            SLA should be more than zero
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="technicalTestForm.controls.sla.touched && technicalTestForm.controls.sla.hasError('max')">
            SLA should be less than 30 days
          </div>
        </div>
      </div>

      <div class="break-line">
      </div>
      <div class="button-group space-between">
        <button type="button" *ngIf="formType === 'jobPost'" (click)="onClickBackButton()"
          class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded  mb-2 me-2">
          Back
        </button>
        <a *ngIf="formType === 'jobPost' && !isBackOfficeRole" class="skip" [routerLink]="'/job/preview/' + jobId">Skip
          this step</a>
        <a *ngIf="formType === 'jobPost' && isBackOfficeRole" class="skip" [routerLink]="'/corporate/details/job-preview/' + companyId + '/' + jobId">Skip
          this step</a>
        <button type="submit" *ngIf="formType !== 'jobPost'" [disabled]="!technicalTestForm.valid"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
        <button type="submit" *ngIf="formType === 'jobPost'"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Next
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </form>
  </div>
</div>
