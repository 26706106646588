<app-spinner [isBackOffice]="isBackOfficeRole" *ngIf="isLoading"></app-spinner>
<div class="dasboard-container" style="margin: 10px 0px;">
  <empty-result style="width: 100%;" [emptyResultType]="'find-talent'"
    *ngIf="talents && talents.length === 0"></empty-result>
  <div class="talent-sidebar" style="width: 100%;" *ngIf="talents && talents.length >= 1">
    <div class="talent-section">
      <div class="talent-card-list-section">
        <div *ngIf="talents" class="row" style="width: 100%; gap:10px">
          <div *ngFor="let talent of talents;">
            <app-talent-card [talent]="talent" [isSelected]="talent.id === selectedTalent?.id"
              (cardSelected)="onSelectTalent(talent)">
            </app-talent-card>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="showPagination" class="talent-pagination-section">
        <pagination [maxPageList]="5" [currentPage]="currentPage" [originTotalPages]="originTotalPages"
          [paginationNumberList]="paginationNumberList" (paginationChange)="onPaginationChange($event)"></pagination>
      </div> -->
    </div>
    <div class="talent-resume">
      <simple-talent-resume *ngIf="selectedTalent" [cardType]="'applicant'" [userProfile]="selectedTalent"
        [applicationStatus]="applicationStatus"></simple-talent-resume>
    </div>
  </div>
</div>
