// Angular modules
import { Injectable, Injector } from '@angular/core';

import { ChartData, InternalAccount, PaginatedCorporateResponse, PaginatedTalentResponse, SidebarSubMenu } from '@interfaces/back-office.interface';
import { CompanyProfile, Job } from '@interfaces/corporate.interface';
import { OnBoardingTalentDetailResponse, TechnicalTest } from '@interfaces/onboarding.interface';
import { BasicInfo, Portfolio, Talent, UserProfile } from '@interfaces/talent.interface';
import { AxiosRequestConfig } from 'axios';
import { BehaviorSubject } from 'rxjs';
import { APIBaseService } from './api-base.service';

@Injectable()
export class BackOfficeService extends APIBaseService {

  private readonly _currentPageUrl = new BehaviorSubject<string>('');
  private isCorporateDetailMenu = new BehaviorSubject<boolean>(false);
  private readonly _activeSidebarMenu = new BehaviorSubject<string>('dashboard');
  private readonly _activeSidebarSubMenu = new BehaviorSubject<SidebarSubMenu>({
    parent: '',
    child: '',
  });
  private readonly _showDetailedMenu = new BehaviorSubject<boolean>(false);
  private readonly _unreadNotification = new BehaviorSubject<number>(0);



  constructor(private injector: Injector) {
    super(injector);
  }

  readonly currentPageUrl$ = this._currentPageUrl.asObservable();
  readonly activeSidebarMenu$ = this._activeSidebarMenu.asObservable();
  readonly activeSidebarSubMenu$ = this._activeSidebarSubMenu.asObservable();
  readonly showDetailedMenu$ = this._showDetailedMenu.asObservable();
  readonly unreadNotification$ = this._unreadNotification.asObservable();

  public setCurrentPageUrl(state: string): void {
    this._currentPageUrl.next(state);
  }

  public setActiveSidebarMenu(state: string): void {
    this._activeSidebarMenu.next(state);
  }

  public setActiveSidebarSubMenu(parent: string, child: string): void {
    this._activeSidebarSubMenu.next({ parent, child, });
  }

  public setShowDetailedMenu(state: boolean): void {
    this._showDetailedMenu.next(state);
  }

  setIsCorporateDetailMenu(isCorporateDetailMenu: boolean) {
    this.isCorporateDetailMenu.next(isCorporateDetailMenu);
  }

  getIsCorporateDetailMenu() {
    return this.isCorporateDetailMenu.asObservable();
  }

  public async getPendingTalentRegistration(params: any): Promise<PaginatedTalentResponse> {
    const url = 'internal/talent/list/pending';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async getAllTalents(params: any): Promise<PaginatedTalentResponse> {
    const url = 'internal/talent/list/all';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async editTalentAbout(talentId: string, about: string): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/about/update`;
    const { data } = await this.baseAPI.post(url, { about });
    return data.data;
  }

  public async editTalentBasicInfo(talentId: string, updatedBasicInfo: Partial<BasicInfo>): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/basic-info/update`;
    const { data } = await this.baseAPI.patch(url, updatedBasicInfo);
    return data.data;
  }

  public async editCompanyBasicInfo(companyId: string, updatedBasicInfo: Partial<any>): Promise<PaginatedTalentResponse> {
    const url = `internal/company/${companyId}/about/update`;
    const { data } = await this.baseAPI.patch(url, updatedBasicInfo);
    return data.data;
  }


  public async editTalentLanguage(talentId: string, languages: Array<string>): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/language/update`;
    const { data } = await this.baseAPI.post(url, { languages });
    return data.data;
  }

  public async updateTalentPersonalWebsite(talentId: string, website: string): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/personal-website/update`;
    const { data } = await this.baseAPI.post(url, { website });
    return data.data;
  }

  public async updateTalentSkills(talentId: string, hardSkills: Array<string>, softSkills: Array<string>): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/skill/update`;
    const { data } = await this.baseAPI.post(url, { hardSkills, softSkills });
    return data.data;
  }

  public async createTalentPortfolio(talentId: string, portfolioPayload: Partial<Portfolio>): Promise<any> {
    const url = `internal/talent/${talentId}/portfolio/add`;
    const { data } = await this.baseAPI.post(url, portfolioPayload);
    return data.data;
  }

  public async updateTalentPortfolio(talentId: string, portfolioId: string, portfolioPayload: Partial<Portfolio>): Promise<any> {
    const url = `internal/talent/${talentId}/portfolio/${portfolioId}/update`;
    const { data } = await this.baseAPI.patch(url, portfolioPayload);
    return data.data;
  }

  public async deleteTalentPortfolio(talentId: string, portfolioId: string): Promise<any> {
    const url = `internal/talent/${talentId}/portfolio/${portfolioId}/delete`;
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async createTalentWorkHistory(talentId: string, payload: Partial<any>): Promise<any> {
    const url = `internal/talent/${talentId}/experience/add`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async updateTalentWorkHistory(talentId: string, experienceId: string, payload: Partial<any>): Promise<any> {
    const url = `internal/talent/${talentId}/experience/${experienceId}/update`;
    const { data } = await this.baseAPI.patch(url, payload);
    return data.data;
  }

  public async deleteTalentWorkHistory(talentId: string, experienceId: string): Promise<any> {
    const url = `internal/talent/${talentId}/experience/${experienceId}/delete`;
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async updateTalentProfilePicture(talentId: string, file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = `internal/talent/${talentId}/profile-picture/update`;
      const { data } = await this.baseAPI.patch(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async removeTalentProfilePicture(talentId: string): Promise<any> {
    const url = `internal/talent/${talentId}/profile-picture/delete`;
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async createJobByCompanyId(companyId: string, payload: Partial<Job>): Promise<Job> {
    const url = `internal/company/${companyId}/job/initiate`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async submiJobByCompanyId(companyId: string, payload: any): Promise<Job> {
    const url = `internal/company/${companyId}/job/submit`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createJobDetailByCompanyId(companyId: string, payload: any): Promise<Job> {
    const url = `internal/company/${companyId}/job/job-detail`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async approveTalentApplication(companyId: string, jobId: string, talentId: string): Promise<Job> {
    const url = `internal/company/${companyId}/job/${jobId}/approve-application`;
    const { data } = await this.baseAPI.post(url, { talentId: talentId });
    return data.data;
  }

  public async declineTalentApplication(companyId: string, jobId: string, talentId: string): Promise<Job> {
    const url = `internal/company/${companyId}/job/${jobId}/decline-application`;
    const { data } = await this.baseAPI.post(url, { talentId: talentId });
    return data.data;
  }

  public async createJobPreferenceByCompanyId(companyId: string, payload: any): Promise<Job> {
    const url = `internal/company/${companyId}/job/application-preference`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createJobTestByCompanyId(companyId: string, payload: any): Promise<Job> {
    const url = `internal/company/${companyId}/job/test-template`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }
  public async getTestTemplateById(jobId: string): Promise<TechnicalTest> {
    const url = `internal/company/test-template/get?jobId=${jobId}`;
    const { data } = await this.baseAPI.get(url);
    return data.data;
  }

  public async getDraftedJobByJobId(companyId: string, jobId: string): Promise<Job> {
    const url = `internal/company/${companyId}/job/draft/${jobId}`;
    const { data } = await this.baseAPI.get(url);
    return data.data;
  }

  public async removeCompanyProfilePicture(companyId: string): Promise<any> {
    const url = `internal/company/${companyId}/profile-picture/delete`;
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async updateCompanyProfilePicture(companyId: string, file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = `internal/company/${companyId}/profile-picture/update`;
      const { data } = await this.baseAPI.patch(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async createTalentEducation(talentId: string, educationPayload: any, isRegistration = false): Promise<PaginatedTalentResponse> {
    const url = `internal/talent/${talentId}/education/add`;
    const { data } = await this.baseAPI.post(url, { ...educationPayload, isRegistration });
    return data.data;
  }

  public async updateTalentEducation(talentId: string, educationId: string, payload: Partial<any>): Promise<any> {
    const url = `internal/talent/${talentId}/education/update/${educationId}`;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async deleteTalentEducation(talentId: string, educationId: string): Promise<any> {
    const url = `internal/talent/${talentId}/education/delete/${educationId}`;
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async approvePendingTalents(talentIds: Array<string>): Promise<any> {
    const url = 'internal/talent/pending/approve/multiple';
    const { data } = await this.baseAPI.post(url, { talentIds });
    return data.data;
  }

  public async rejectPendingTalents(talentIds: Array<string>, rejectReason: string): Promise<any> {
    const url = 'internal/talent/pending/reject/multiple';
    const { data } = await this.baseAPI.post(url, { talentIds, reason: rejectReason });
    return data.data;
  }

  public async getTalentById(talentId: string): Promise<UserProfile> {
    const url = `internal/talent/${talentId}`;
    const { data } = await this.baseAPI.get(`${url}`);
    return data.data;
  }

  public async getDetailedOnBoardingTalent(onBoardingId: string): Promise<OnBoardingTalentDetailResponse> {
    const url = `internal/company/onboardings/detail/${onBoardingId}`;
    const { data } = await this.baseAPI.get(`${url}`);
    return data.data;
  }

  public async createJobTechnicalTest(payload: any): Promise<any> {
    const url = `internal/job-onboarding/test`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }


  public async submitTechnicalTest(payload: any, result: string): Promise<any> {
    let action = 'approve';
    if (result === 'declined') {
      action = 'reject'
    }
    const url = `internal/job-onboarding/${action}-test`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createJobTechnicalTestTemplate(companyId: string, payload: any): Promise<any> {
    const url = `internal/company/${companyId}/job/test-template`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async uploadTestQuestion(jobId: string, file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      formData.append('jobId', jobId);
      const url = `internal/job-onboarding/test-upload`;
      const { data } = await this.baseAPI.post(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async downloadResume(talentId: string, firstName: string, lastName: string): Promise<any> {
    const url = `file/talent/${talentId}/download`;
    const { data } = await this.baseAPI.post(url, {}, { responseType: 'blob' });
    const url2 = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url2;
    link.setAttribute('download', `${firstName} ${lastName}-${talentId}.pdf`)
    document.body.appendChild(link)
    link.click()
    return data.data;
  }

  public async downloadTalentResume(talent: Talent): Promise<any> {
    const url = `file/talent/${talent.talentId}/download`;
    const { data } = await this.baseAPI.post(url, {}, { responseType: 'blob' });
    const url2 = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url2;
    link.setAttribute('download', `${talent.firstName} ${talent.lastName}-${talent.talentId}.pdf`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url2);
    return data.data;
  }

  public async downloadBulkTemplate(type: string): Promise<any> {
    const url = `internal/${type}/template/download`;
    const { data } = await this.baseAPI.get(url, { responseType: 'blob' });
    const url2 = window.URL.createObjectURL(new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }))
    const link = document.createElement('a')
    link.href = url2;
    link.setAttribute('download', `bulk-upload-${type}-template.xlsx`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url2);
    return data.data;
  }

  public async bulkUpload(file: File | null, type: string): Promise<any> {
    if (file) {
      const config: AxiosRequestConfig<FormData> = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = `internal/${type}/upload/insert`;
      const { data } = await this.baseAPI.post(url, formData, config);
      const url2 = window.URL.createObjectURL(new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }))
      const link = document.createElement('a')
      link.href = url2;
      link.setAttribute('download', `bulk-upload-${type}-response.xlsx`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url2);
      return data.data;
    }
    return Promise.resolve()
  }

  public async downloadMultipleTalentResume(talentIds: string[]): Promise<any> {
    talentIds.forEach(async (talentId) => {
      const talent = await this.getTalentById(talentId)
      const url = `file/talent/${talentId}/download`;
      const { data } = await this.baseAPI.post(url, {}, { responseType: 'blob' });
      const url2 = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url2;
      link.setAttribute('download', `${talent.firstName} ${talent.lastName}-${talentId}.pdf`)
      document.body.appendChild(link)
      link.click()
    })

    return talentIds;
  }

  public async getDashboardSummaryByType(summaryType: string): Promise<Array<ChartData>> {
    const url = `internal/dashboard/summary`;
    const { data } = await this.baseAPI.get(`${url}`, { params: { summaryType } });
    return data.data;
  }



  public async getTodayRegistratiobByType(newDataType: string): Promise<number> {
    const url = `internal/dashboard/today`;
    const { data } = await this.baseAPI.get(`${url}`, { params: { newDataType } });
    return data.data?.count?.count;
  }

  public async getCompanyById(companyId: string): Promise<CompanyProfile> {
    const url = `internal/company/${companyId}`;
    const { data } = await this.baseAPI.get(`${url}`);
    return data.data;
  }

  public async getPendingCorporateRegistration(params: any): Promise<PaginatedCorporateResponse> {
    const url = 'internal/corporate/list/pending';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async getAllCorporateList(params: any): Promise<PaginatedCorporateResponse> {
    const url = 'internal/corporate/list/all';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async approvePendingCorporate(corporateId: string, corporateType: string): Promise<any> {
    const url = 'internal/corporate/pending/approve';
    const { data } = await this.baseAPI.post(url, { corporateId, corporateType });
    return data.data;
  }

  public async getInternalAccounts(params: any): Promise<any> {
    const url = 'internal/internal/accounts';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async addInternalAccount(payload: Partial<InternalAccount>): Promise<any> {
    const url = 'internal/internal/initiate-user';
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async editInternalAccount(payload: Partial<InternalAccount>): Promise<any> {
    const url = 'internal/internal/user/update';
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async rejectPendingCorporates(corporateIds: Array<string>, rejectReason: string): Promise<any> {
    const url = 'internal/corporate/pending/reject/multiple';
    const { data } = await this.baseAPI.post(url, { corporateIds, reason: rejectReason });
    return data.data;
  }

  public async getPendingTask(companyId: string, summaryType: string): Promise<number> {
    const url = `internal/company/${companyId}/summary`;
    const { data } = await this.baseAPI.get(url, { params: { summaryType } });
    return data.data.count;
  }

  public async updateFollowUpStatus(corporateId: string, status: string): Promise<number> {
    const url = `internal/corporate/pending/follow-up`;
    const { data } = await this.baseAPI.post(url, { corporateId, status });
    return data.data;
  }

  public async getJobPostListByCompanyId(companyId: string, params: any): Promise<any> {
    const url = `internal/company/${companyId}/job/list`;
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async getCompanyJobById(companyId: string, jobId: string): Promise<Job> {
    const url = `internal/company/${companyId}/job/detail/${jobId}`;
    const { data } = await this.baseAPI.get(url);
    return data.data;
  }

  public async getAppliedTalentList(companyId: string, jobId: string, params: any): Promise<Job> {
    const url = `internal/company/${companyId}/job/${jobId}/applications`;
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }
  public async getCompanyDraftJobById(companyId: string, jobId: string): Promise<Job> {
    const url = `internal/company/${companyId}/job/draft/${jobId}`;
    const { data } = await this.baseAPI.get(url);
    return data.data;
  }

  public async approvePendingJob(companyId: string, jobId: string): Promise<any> {
    const url = `internal/company/${companyId}/job/approve`;
    const { data } = await this.baseAPI.post(url, { jobId });
    return data.data;
  }

  public async rejectPendingJob(companyId: string, jobId: string, rejectedReason: string): Promise<any> {
    const url = `internal/company/${companyId}/job/reject`;
    const { data } = await this.baseAPI.post(url, { jobId, reason: rejectedReason });
    return data.data;
  }

  public async getTalentOnBoardingListByType(params: any): Promise<any> {
    const url = `internal/talent/list/onboarding`;
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async getNotifications(params: any): Promise<any> {
    const url = 'internal/dashboard/notification/list';
    const { data } = await this.baseAPI.get(url, { params });
    return data.data;
  }

  public async readNotification(notificationId: string): Promise<any> {
    const url = 'internal/dashboard/notification/read';
    const { data } = await this.baseAPI.post(url, { notificationId });
    return data.data;
  }

  public async getUnredNotificationCount(): Promise<number> {
    const url = 'internal/dashboard/notification/unread-count';
    const { data } = await this.baseAPI.get(url);
    const unreadCount = data.data.count;
    this._unreadNotification.next(unreadCount);
    return unreadCount;
  }
}
