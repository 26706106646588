import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { UIHelper } from '@helpers/ui.helper';
import { CardType } from '@interfaces/corporate.interface';
import { BasicInfo, Education, Portfolio, UserProfile, WorkHistory } from '@interfaces/talent.interface';
import { ConfirmationDialogComponent } from '@modals/confirmation-dialog/confirmation-dialog.component';
import { EducationFormComponent } from '@pages/register/education/education-form/education-form.component';
import { BasicInfoFormComponent } from '@pages/register/personal-info/basic-info-form/basic-info-form.component';
import { PhotoProfileFormComponent } from '@pages/register/personal-info/photo-profile-form/photo-profile-form.component';
import { LanguageFormComponent } from '@pages/register/skill-resume/language-form/language-form.component';
import { PersonalWebsiteComponent } from '@pages/register/skill-resume/personal-website/personal-website.component';
import { PortfolioFormComponent } from '@pages/register/skill-resume/portfolio-form/portfolio-form.component';
import { SkillFormComponent } from '@pages/register/skill-resume/skill-form/skill-form.component';
import { WorkHistoryFormComponent } from '@pages/register/work-history/work-history-form/work-history-form.component';
import { AboutComponent } from '@pages/talent/about/about.component';
import { BackOfficeService } from '@services/back-office.service';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { ModalInterface, ModalService } from '@services/modal.service';
import { SnackbarService } from '@services/snackbar.service';
import { TalentService } from '@services/talent.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'simple-talent-resume',
  templateUrl: './simple-talent-resume.component.html',
  styleUrls: ['./simple-talent-resume.component.scss']
})
export class SimpleTalentResumeComponent implements OnInit {

  public isLoading: boolean = false;
  @Input() userProfile!: UserProfile;
  @Input() applicationStatus!: Number | undefined;
  @Input() isPlacementDetail = false;
  @Input() cardType: CardType = 'viewedBy';
  private destroy$ = new Subject<void>();
  public isOfferingButtonShown: boolean = true;
  public errorPopUpMessage = '';
  public rejectReason = '';
  public jobId = '';
  public companyId = '';
  @ViewChildren('resize') basicElements!: QueryList<ElementRef>;
  public isBackOfficeRole = false;

  constructor(
    private talentService: TalentService,
    private jobService: JobService,
    private router: Router,
    private corporateService: CorporateService,
    private backOfficeService: BackOfficeService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private toastManager: ToastManager,
    private modalService: ModalService,
    public dialog: MatDialog
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.isBackOfficeRole = StorageHelper.isBackOfficeRole;
    // this.corporateService.cardType$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(async (cardType) => {
    //     this.cardType = cardType;
    //     if (cardType === 'pending-registration') {
    //       this.backOfficeService.setActiveSidebarMenu('talent')
    //       this.backOfficeService.setActiveSidebarSubMenu('registration', 'pending')
    //     }
    //     if (cardType === 'back-office' && !this.isPlacementDetail) {
    //       this.backOfficeService.setActiveSidebarMenu('talent')
    //       this.backOfficeService.setActiveSidebarSubMenu('talent', 'account')
    //     }
    //     this.isOfferingButtonShown = false;
    //     if (['viewedBy'].includes(cardType)) {
    //       this.isOfferingButtonShown = true;
    //       return;
    //     }
    //   });
    this.route.params
      .subscribe(async (params) => {
        this.jobId = params['jobId'];
        this.companyId = params['companyId'];
      })
  }

  private async getDasboardData() {
    this.isLoading = true;
    try {
      if (this.userProfile.id) {
        this.userProfile = await this.backOfficeService.getTalentById(this.userProfile.id)
      }

    } catch (error: any) {
      console.log(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeEducation(selectedEducation: Education) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedEducation.schoolName}" \n Are you sure you want to remove this education?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          if (StorageHelper.isBackOfficeRole && this.userProfile?.id) {
            await this.backOfficeService.deleteTalentEducation(this.userProfile?.id, selectedEducation.id);
            this.userProfile = await this.backOfficeService.getTalentById(this.userProfile.id)
          } else {
            await this.talentService.removeEducation(selectedEducation.id);
            const updatedUserProfile = await this.talentService.getProfile();
            this.userProfile = updatedUserProfile;
          }

          this.toastManager.showSuccess('Education removed successfully')
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  public editPhotoProfile() {
    const basicInfo: Partial<BasicInfo> = {
      profilePictureUrl: this.userProfile?.profilePictureUrl,
    }
    const modalConfig: ModalInterface = {
      component: PhotoProfileFormComponent,
      customLayout: {
        width: '410px',
      },
      data: {
        basicInfo: basicInfo,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public addNewEducation() {
    const modalConfig: ModalInterface = {
      component: EducationFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }


  public editBasicInfo() {
    const basicInfo: Partial<BasicInfo> = {
      firstName: this.userProfile?.firstName,
      lastName: this.userProfile?.lastName,
      email: this.userProfile?.email,
      phone: this.userProfile?.phone,
      position: this.userProfile?.position,
      isLookingForWork: this.userProfile?.isLookingForWork,
      preferredTimezone: this.userProfile?.preferredTimezone,
      currentCompany: this.userProfile?.currentCompany,
      profilePictureUrl: this.userProfile?.profilePictureUrl,
    }
    const modalConfig: ModalInterface = {
      component: BasicInfoFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        basicInfo: basicInfo,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }


  public addNewWorkHistory() {
    const modalConfig: ModalInterface = {
      component: WorkHistoryFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editWorkHistory(selectedWorkHistory: WorkHistory) {
    const modalConfig: ModalInterface = {
      component: WorkHistoryFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        selectedWorkHistory,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async removeWorkHistory(selectedWorkHistory: WorkHistory) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedWorkHistory.companyName}" \n Are you sure you want to remove this work experience?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          if (this.isBackOfficeRole && this.userProfile?.id) {
            await this.backOfficeService.deleteTalentWorkHistory(this.userProfile?.id, selectedWorkHistory.id);
            this.userProfile = await this.backOfficeService.getTalentById(this.userProfile.id)
          } else {
            await this.talentService.removeWorkHistory(selectedWorkHistory.id);
            const updatedUserProfile = await this.talentService.getProfile();
            this.userProfile = updatedUserProfile;
          }

          this.toastManager.showSuccess('Work experience removed successfully');
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  public editPersonalWebsite() {
    const modalConfig: ModalInterface = {
      component: PersonalWebsiteComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        personalWebsite: this.userProfile?.resume?.personalWebsite,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public addNewPortfolio() {
    const modalConfig: ModalInterface = {
      component: PortfolioFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editPortfolio(selectedPortfolio: Portfolio) {
    const modalConfig: ModalInterface = {
      component: PortfolioFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        selectedPortfolio,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async removePortfolio(selectedPortfolio: Portfolio) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedPortfolio.projectName}" \n Are you sure you want to remove this portofolio?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          if (StorageHelper.isBackOfficeRole && this.userProfile?.id) {
            await this.backOfficeService.deleteTalentPortfolio(this.userProfile?.id, selectedPortfolio.id);
            this.userProfile = await this.backOfficeService.getTalentById(this.userProfile.id)
          } else {
            await this.talentService.removePortfolio(selectedPortfolio.id);
            const updatedUserProfile = await this.talentService.getProfile();
            this.userProfile = updatedUserProfile;
          }

          this.toastManager.showSuccess('Portofolio removed successfully');
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  public editSkills() {
    const modalConfig: ModalInterface = {
      component: SkillFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        hardSkills: this.userProfile?.hardSkills,
        softSkills: this.userProfile?.softSkills,
        talentId: this.userProfile?.id,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editLanguage() {
    const modalConfig: ModalInterface = {
      component: LanguageFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        languages: this.userProfile?.languages,
        talentId: this.userProfile?.id,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editEducation(selectedEducation: Education) {
    const modalConfig: ModalInterface = {
      component: EducationFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        selectedEducation,
        talentId: this.userProfile?.id
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editAbout() {
    const modalConfig: ModalInterface = {
      component: AboutComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        about: this.userProfile?.about,
        talentId: this.userProfile?.id,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async onApproveTalentClick() {
    try {
      if (this.userProfile.id) {
        await this.backOfficeService.approvePendingTalents([this.userProfile.id]);
        this.toastManager.showSuccess(`Talent has been approved successfully`)
        this.router.navigate(['talent/account'])
      }
    } catch (error: any) {
      this.errorPopUpMessage = error.message
    } finally {
      this.isLoading = false
    }
  }

  public onGiveAnOfferClick() {
    this.talentService.setOfferedTalentId(this.userProfile.id)
    this.router.navigate(['talent-offer'])
  }

  public async onCloseModal() {
    this.errorPopUpMessage = '';
    this.snackbarService.close();;
  }

  public async openRejetedModal() {

    this.snackbarService.open('reject-talent');
  }

  public async onClickDownloadClick(): Promise<void> {
    if (this.userProfile.id) {
      try {
        this.toastManager.showSuccess('Downloading resume');
        await this.backOfficeService.downloadResume(this.userProfile.id, this.userProfile.firstName, this.userProfile.lastName);
      } catch (error: any) {
        this.toastManager.showError('Download resume failed', error.message);
      }
    }
  }

  public async rejectTalent() {
    try {
      if (this.userProfile.id) {
        const talentIds = [this.userProfile.id]
        await this.backOfficeService.rejectPendingTalents(talentIds, this.rejectReason);
        this.toastManager.showSuccess('Talent has been rejected successfully');
        this.onCloseModal();
        this.router.navigate(['talent/registration/pending'])
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async approveApplicantForJob() {
    try {
      this.isLoading = true
      if (this.userProfile.id && this.jobId) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '340px',
          data: {
            title: 'Confirmation',
            message: ` Are you sure you want to approve this applicant?`
          }
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            try {
              if (this.userProfile.id && this.jobId) {
                if (this.isBackOfficeRole) {
                  await this.backOfficeService.approveTalentApplication(this.companyId, this.jobId, this.userProfile.id);
                  this.toastManager.showSuccess(`Talent has been approved successfully`)
                  this.router.navigate(['corporate/details/job-overview', this.companyId, this.jobId])
                } else {
                  await this.jobService.approveApplicantForJob(this.jobId, this.userProfile.id);
                  this.toastManager.showSuccess('Applicant has been approved successfully');
                  this.router.navigate(['corporate/on-boarding', this.jobId])
                }
              }
              dialogRef.close();
            } catch (error: any) {
              this.toastManager.showError(null, error.message)
            } finally {
              this.isLoading = false;
            }
          }
        })
      }
    } catch (error: any) {
      this.toastManager.showError('Failed to approve applicant', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async declineApplicantForJob() {
    try {
      this.isLoading = true;
      if (this.userProfile.id && this.jobId) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '340px',
          data: {
            title: 'Confirmation',
            message: ` Are you sure you want to decline this applicant?`
          }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            try {
              if (!this.userProfile.id || !this.jobId) {
                return;
              }
              if (this.isBackOfficeRole) {
                await this.backOfficeService.declineTalentApplication(this.companyId, this.jobId, this.userProfile.id);
                this.toastManager.showSuccess(`Talent has been declined successfully`)
                this.router.navigate(['corporate/details/job-overview', this.companyId, this.jobId])
              } else {
                await this.jobService.declineApplicantForJob(this.jobId, this.userProfile.id);
                this.toastManager.showSuccess('Applicant has been declined successfully');
                this.corporateService.setCardType('applicant')
              }

              dialogRef.close();
            } catch (error: any) {
              this.toastManager.showError('Failed to decline applicant', error.message)
            } finally {
              this.isLoading = false;
            }
          }
        })


      }
    } catch (error: any) {
      this.toastManager.showError('Failed to decline applicant', error.message)
    } finally {
      this.isLoading = false
    }
  }

  ngAfterViewInit() {
    UIHelper.resizeWidthToColumn(this.basicElements, 550);
    window.addEventListener('resize', () => UIHelper.resizeWidthToColumn(this.basicElements, 550));
  }
}
