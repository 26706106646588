import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { CompanyProfile, Job } from '@interfaces/corporate.interface';

@Component({
  selector: 'job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.scss']
})
export class JobInfoComponent implements OnInit {
  @Input() job !: Job;
  @Input() companyProfile !: CompanyProfile;

  public showFullCompanyAbout = false;
  public truncatedCompanyAbout!: string | undefined;
  public truncatedNumber = 300;
  public showReadMore = false;

  ngOnInit() {
    if (this.companyProfile?.about?.length > 300) {
      this.truncatedCompanyAbout = `${this.companyProfile?.about?.slice(0, this.truncatedNumber)} ...`;
      this.showReadMore = true;
    } else {
      this.truncatedCompanyAbout = this.companyProfile?.about || '-';
      this.showReadMore = false;
    }
  }


  public toggleFullCompanyAbout() {
    this.showFullCompanyAbout = !this.showFullCompanyAbout;
  }

  public openNewTab(url: string) {
    window.open(url, '_blank');
  }

}
