import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterHidden'
})
export class FilterHiddenPipe implements PipeTransform {
  transform(items: any[], key: string): any[] {
    return items.filter(item => !item[key]);
  }
}
